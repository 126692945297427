// src/GameBoard.js
import React from 'react';
import { motion } from 'framer-motion';
import ModernX from './icons/ModernX.svg';
import ModernO from './icons/ModernO.svg';

const GameBoard = ({ handleClick, boardState, highlight, isDraw }) => {
  return (
    <div className="relative w-80 h-80 md:h-96 md:w-96 grid grid-cols-3 grid-rows-3 gap-0 bg-gray-900">
      {boardState.map((value, index) => {
        const isWinningCell = highlight[index];
        const borderClasses = isDraw ? 'border-gray-400 pulse-effect' : 'border-gray-300';

        return (
          <motion.div
            key={index}
            className={`flex items-center justify-center cursor-pointer
              border-2 border-white
              ${borderClasses}
              ${index === 0 ? 'border-l-0 border-t-0 border-b-0' : ''}
              ${index === 1 ? 'border-none' : ''}
              ${index === 2 ? 'border-t-0 border-r-0 border-b-0' : ''}
              ${index === 3 ? 'border-l-0 border-b-0' : ''}
              ${index === 4 ? 'border-r-0 border-b-0 border-l-0' : ''}
              ${index === 5 ? 'border-r-0 border-b-0' : ''}
              ${index === 6 ? 'border-b-0 border-l-0' : ''}
              ${index === 7 ? 'border-r-0 border-b-0 border-l-0' : ''}
              ${index === 8 ? 'border-r-0 border-b-0' : ''}
              `}
            onClick={() => handleClick(index)}
            //whileHover={{ scale: 1.05 }}
            // whileTap={{ scale: 0.95 }}
          >
            {value === 'X' && (
              <motion.img
                src={ModernX}
                alt="X"
                className={`w-16 h-16 ${isWinningCell ? 'animate-win' : ''}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              />
            )}
            {value === 'O' && (
              <motion.img
                src={ModernO}
                alt="O"
                className={`w-16 h-16 ${isWinningCell ? 'animate-win' : ''}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              />
            )}
          </motion.div>
        );
      })}
    </div>
  );
};

export default GameBoard;
