// src/App.js
import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import GameBoard from './GameBoard';
import Scoreboard from './Scoreboard';

const App = () => {
  const [boardState, setBoardState] = useState(Array(9).fill(null));
  const [currentPlayer, setCurrentPlayer] = useState('X');
  const [gameOver, setGameOver] = useState(false);
  const [highlight, setHighlight] = useState(Array(9).fill(false));
  const [scores, setScores] = useState({ X: 0, O: 0, Draw: 0 });
  const [winner, setWinner] = useState(null);
  const [isDraw, setIsDraw] = useState(false);

  const handleClick = (index) => {
    if (boardState[index] || gameOver) return;

    const newBoardState = [...boardState];
    newBoardState[index] = currentPlayer;
    setBoardState(newBoardState);

    const winner = checkWinner(newBoardState);
    if (winner) {
      highlightWinningCombo(newBoardState, winner);
      setWinner(winner);
      setGameOver(true);
      setTimeout(() => {
        updateScores(winner);
        resetGame();
        setWinner(null);
        setIsDraw(false);
      }, 2000);
      return;
    }

    if (newBoardState.every(cell => cell !== null)) {
      setHighlight(Array(9).fill(false));
      setIsDraw(true);
      setGameOver(true);
      setTimeout(() => {
        updateScores('Draw');
        resetGame();
        setIsDraw(false);
      }, 2000);
      return;
    }

    setCurrentPlayer('O');
    setTimeout(() => computerMove(newBoardState), 500);
  };

  const checkWinner = (board) => {
    const winningCombos = [
      [0, 1, 2], [3, 4, 5], [6, 7, 8],
      [0, 3, 6], [1, 4, 7], [2, 5, 8],
      [0, 4, 8], [2, 4, 6]
    ];
    for (let [a, b, c] of winningCombos) {
      if (board[a] && board[a] === board[b] && board[a] === board[c]) {
        return board[a];
      }
    }
    return null;
  };

  const highlightWinningCombo = (board, winner) => {
    const winningCombos = [
      [0, 1, 2], [3, 4, 5], [6, 7, 8],
      [0, 3, 6], [1, 4, 7], [2, 5, 8],
      [0, 4, 8], [2, 4, 6]
    ];
    let winningIndices = [];
    for (let [a, b, c] of winningCombos) {
      if (board[a] === winner && board[a] === board[b] && board[a] === board[c]) {
        winningIndices = [a, b, c];
        break;
      }
    }
    const highlightArray = Array(9).fill(false);
    winningIndices.forEach(index => highlightArray[index] = true);
    setHighlight(highlightArray);
  };

  const updateScores = (result) => {
    setScores(prevScores => ({
      ...prevScores,
      [result]: prevScores[result] + 1
    }));
  };

  const computerMove = (currentBoard) => {
    const bestMove = getBestMove(currentBoard);
    if (bestMove !== undefined) {
      const newBoardState = [...currentBoard];
      newBoardState[bestMove] = 'O';
      setBoardState(newBoardState);

      const winner = checkWinner(newBoardState);
      if (winner) {
        highlightWinningCombo(newBoardState, winner);
        setWinner(winner);
        setGameOver(true);
        setTimeout(() => {
          updateScores('O');
          resetGame();
          setWinner(null);
        }, 2000);
      } else {
        setCurrentPlayer('X');
      }
    }
  };

  const getBestMove = (board) => {
    let bestMove = -1;
    let bestScore = -Infinity;

    // List of possible moves
    let possibleMoves = board.map((cell, index) => cell === null ? index : null).filter(index => index !== null);

    for (let move of possibleMoves) {
        board[move] = 'O'; // Assume AI is 'O'
        let score = minimax(board, 0, false);
        board[move] = null;

        // Add some randomness
        const randomFactor = Math.random() * 0.15 - 0.1; // Randomness between -0.1 and 0.1
        score += randomFactor;

        if (score > bestScore) {
            bestScore = score;
            bestMove = move;
        }
    }
    return bestMove;
  };

  const minimax = (board, depth, isMaximizing) => {
    const scores = { 'X': -10, 'O': 10, 'Draw': 0 };
    let result = checkWinner(board);
    if (result !== null) {
        return scores[result];
    }

    // Limit the depth to make the AI less perfect
    if (depth >= 2) {
        return scores['Draw']; // Returning a neutral score
    }

    if (isMaximizing) {
        let bestScore = -Infinity;
        for (let i = 0; i < board.length; i++) {
            if (board[i] === null) {
                board[i] = 'O';
                let score = minimax(board, depth + 1, false);
                board[i] = null;
                bestScore = Math.max(score, bestScore);
            }
        }
        return bestScore;
    } else {
        let bestScore = Infinity;
        for (let i = 0; i < board.length; i++) {
            if (board[i] === null) {
                board[i] = 'X';
                let score = minimax(board, depth + 1, true);
                board[i] = null;
                bestScore = Math.min(score, bestScore);
            }
        }
        return bestScore;
    }
  };

  const resetGame = () => {
    setBoardState(Array(9).fill(null));
    setCurrentPlayer('X');
    setGameOver(false);
    setHighlight(Array(9).fill(false));
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-900">
      <main className="flex-grow flex flex-col items-center justify-center p-4">
        <Scoreboard scores={scores} />
        <div className="relative w-80 h-80 md:h-96 md:w-96 mt-4">
          <GameBoard
            handleClick={handleClick}
            boardState={boardState}
            highlight={highlight}
            isDraw={isDraw}
          />
        </div>
      </main>
    </div>
  );
};

export default App;
