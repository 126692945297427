// src/Scoreboard.js
import React from 'react';

const Scoreboard = ({ scores }) => {
  return (
    <div className="bg-gray-900 p-4 rounded-lg shadow-lg flex justify-between w-full max-w-sm">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col items-center justify-between w-full text-lg font-medium">
          <span className="text-white tracking-wider">Player X</span>
          <span className='text-white'>{scores.X}</span>
        </div>
        <div className="flex flex-col items-center justify-between w-full text-lg font-medium">
          <span className="text-white tracking-wider">Tie</span>
          <span className='text-white'>{scores.Draw}</span>
        </div>
        <div className="flex flex-col items-center justify-between w-full text-lg font-medium">
          <span className="text-white tracking-wider">Comp O</span>
          <span className='text-white'>{scores.O}</span>
        </div>
      </div>
    </div>
  );
};

export default Scoreboard;
